import React from 'react';

/**
 * フッター情報
 */
class Footer extends React.Component {
  /**
   * 初期化
   * @param {req} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.smoothScroll.bind(this);
  }

  /**
   * 指定要素へ scroll
   * @param {Object} e
   * @param {String} targetId
   */
  smoothScroll(e, targetId) {
    e.preventDefault();
    const target = $(`#${targetId}`).offset().top;
    const speed = 400;
    $('body,html').animate({scrollTop: target}, speed, 'swing');
  };

  /**
   * レンダリング
   * @return {JSX}
   */
  render() {
    return (
      <React.Fragment>
        <footer id="footer">
          <div id="backTop"><a href="#TOP" onClick={(e) => this.smoothScroll(e, 'TOP')}>TOPへ↑</a></div>
          <small id="copy">Copyright &copy; 2014 D.C.T. Corporation. All Rights Reserved.</small>
        </footer>
      </React.Fragment>
    );
  };
};

export default Footer;

import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Page from './page/index';

/**
 * React の Routing 設定
 */
class Routing extends React.Component {
  /**
   * React の Routing 設定
   * @return {Object} Routing 設定
   */
  render() {
    const router = createBrowserRouter([
      {path: '/', element: <Page.Top/>},
    ]);

    return (<RouterProvider router={router} />);
  }
}

export default Routing;

import React from 'react';
import {createRoot} from 'react-dom/client';
import Routing from './routing';
// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
import Footer from 'CommonRoot/footer';

/**
 * Bugsnag 初期設定
 * @return {Object} Bugsnag tag
 */
// function initBugsnag() {
//   Bugsnag.start({
//     apiKey: '',
//     plugins: [new BugsnagPluginReact()],
//     releaseStage: process.env.ENV,
//   });
//   return Bugsnag.getPlugin('react').createErrorBoundary(React);
// }

/**
 * メインレイアウト
 * @return {Object} メインレイアウト
 */
function mainLayout() {
  return (
    <>
      <Routing />
      <Footer />
    </>
  );
};

/**
 * APP
 * @return {JSX} React共通
 */
function App() {
  // if (process.env.SERVICE_ENV == 'local') {
  return mainLayout();
  // }

  // const ErrorBoundary = initBugsnag();

  // return (
  //   <ErrorBoundary>
  //     {mainLayout()}
  //   </ErrorBoundary>
  // );
}

createRoot(document.getElementById('TOP')).render(<App />);

import React from 'react';

/**
 * TOP ページ
 */
class Top extends React.Component {
  /**
   * 初期化
   * @param {req} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.smoothScroll.bind(this);
  }

  /**
   * 指定要素へ scroll
   * @param {Object} e
   * @param {String} targetId
   */
  smoothScroll(e, targetId) {
    e.preventDefault();
    const target = $(`#${targetId}`).offset().top;
    const speed = 400;
    $('body,html').animate({scrollTop: target}, speed, 'swing');
  };

  /**
   * レンダリングマウント後実行
   */
  componentDidMount() {
  }

  /**
   * @return {JSX}
   */
  render() {
    return (
      <>
        <header id='header' className='clearfix'>
          <h1 id='logo'><a href='/'><img src='/img/logo.png' alt='株式会社D.C.T.' /></a></h1>
        </header>
        <div id='contents'>
          {/* トップ画像 */}
          <div id='imgWrapper'>
            <img src='/img/dct_image.png' alt='Dreams. Come. True.' width='100%' />
            <div id='recruitBtn'>{/* <a href='./recruit'><img src='./img/recruit_btn.png' alt='採用情報' width='315' className='imghover' /></a> */}</div>
          </div>

          {/* サブメニュー */}
          <ul id='subNav'>
            <li><a href='#message' onClick={(e) => this.smoothScroll(e, 'message')}>社長メッセージ</a></li>
            <li><a href='#business' onClick={(e) => this.smoothScroll(e, 'business')}>事業内容</a></li>
            <li><a href='#company' onClick={(e) => this.smoothScroll(e, 'company')}>会社概要<br />アクセスマップ</a></li>
          </ul>

          {/* 社長メッセージ */}
          <section id='message' className='clearfix'>

            <h2 className='selector01'>社長メッセージ</h2>

            <div className='txtBox'>
              <div className='imgBox'><img src='/img/re_IMGP0334.jpg' width='300px' alt='D.C.T.' /></div>
              {/* eslint-disable-next-line no-irregular-whitespace */}
              <p>社名（D. C. T.）の由来は、<span className='cRed'>Dreams Come True　夢は叶う　</span>です。<br />
                仕事を通していろいろな事柄を吸収し成長して、みなさんの思っている“叶えたい夢”を実現するサポートをしていきたいと思います。<br />
                それは社員も同様です。提供するサービスを通じて私を含め社員全員が夢に近づけることを目指します。
              </p>
              {/* eslint-disable-next-line no-irregular-whitespace */}
              <p>大学卒業後より、ITと呼ばれる業界に長く携わってきましたが、１年１年の変化が非常に激しく、またビジネスとしても楽な道のりではないこと、１プレイヤーとしても常に勉強の日々であることを痛感しております。</p>

              <p>成功している先人の方たちを見ると、その勉強を日課のように無意識に行い、情報のアンテナを張り巡らし、コミュニケーションを大切にしている方が非常に多いです。</p>

              <p>当社はまだまだ小さな会社で、決して楽な道のりではないですが、やりたいことを実現するためにしっかり研究、勉強をし、見聞を広め、ご自身の成長にもつながるようなビジネスができればと思っています。</p>


              <p>
                株式会社D.C.T.<br />
                {/* eslint-disable-next-line no-irregular-whitespace */}
                代表取締役　橋根 善幸
              </p>
            </div>
          </section>

          {/* 事業内容 */}
          <section id='business'>

            <h2 className='selector01'>事業内容</h2>

            <section className='businessBox'>
              <h3 className='selector02'>事業内容</h3>
              <dl>
                <dt>1）スマートフォンアプリの企画・開発・運営</dt>
                <dd>
                  iPhone、GooglePlay、その他対応のアプリ企画・開発・運営。<br />
                  B to C、B to B to C、C to C、O to Oサービス等、多種多様なサービス形態に対応。
                </dd>

                <dt>2）ソーシャルゲームの企画・開発・運営</dt>
                <dd>
                  ネイティブ（iPhone、GooglePlay）のアプリ企画・開発・運営。<br />
                  各PF（mobage、Gree、mixi、etc.）対応のゲーム企画・開発・運営。
                </dd>

                <dt>3）Webサービス、アプリの企画・開発・運営</dt>
                <dd>
                  スマートフォンアプリとの連動、ソーシャルゲーム要素の組み入れ等、<br />
                  クライアントニーズに対応したサービス企画。
                </dd>

                <dt>4）インターネットサービスに関するコンサルティング</dt>
              </dl>
            </section>

            <section className='businessBox'>
              <h3 className='selector02'>主要お取引先</h3>
              <ul>
                <li>・株式会社バンダイナムコエンターテインメント</li>
              </ul>
            </section>
          </section>

          {/* 会社概要 */}
          <section id='company'>

            <h2 className='selector01'>会社概要</h2>

            <div id='compWrapper'>
              <div id='profile'>
                <dl>
                  <dt>会社名</dt>
                  <dd>株式会社D.C.T.</dd>
                </dl>
                <dl>
                  <dt>設立日</dt>
                  <dd>2013年3月15日</dd>
                </dl>
                <dl>
                  <dt>所在地</dt>
                  <dd>
                    〒135-0063 東京都江東区有明３丁目６−１１ TFTビル東館８F
                  </dd>
                </dl>
                {/* <dl>
                  <dt>電 話</dt>
                  <dd>03-6382-8582</dd>
                </dl>
                <dl>
                  <dt>FAX</dt>
                  <dd>03-6382-8591</dd>
                </dl> */}
                <dl>
                  <dt>代表者</dt>
                  <dd>橋根 善幸</dd>
                </dl>
                <dl>
                  <dt>資本金</dt>
                  <dd>1,000,000円</dd>
                </dl>
              </div>

              {/* アクセスマップ */}
              <div id='map'>

                <h3 className='selector03'>アクセスマップ</h3>

                {/* eslint-disable max-len */}
                <div id='mapimg'>
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.8714575618624!2d139.78843931525742!3d35.63089528020608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601889e62de935af%3A0xa1eea98fa818da71!2z44CSMTM1LTAwNjMg5p2x5Lqs6YO95rGf5p2x5Yy65pyJ5piO77yT5LiB55uu77yW4oiS77yR77yR!5e0!3m2!1sja!2sjp!4v1585731861730!5m2!1sja!2sjp'
                    width='600'
                    height='450'
                    frameBorder='0'
                    style={{'border': '0'}}
                    allowFullScreen=''
                    aria-hidden='false'
                    tabIndex='0'
                  >
                  </iframe>
                </div>
                {/* eslint-enable max-len */}

                <p id='gMap'><a href='https://goo.gl/maps/ZXTGRhmoddp3B9Fs9' target='blank'>Google Mapで表示</a></p>

              </div>
            </div>
          </section>
        </div>
      </>
    );
  };
};

export default Top;
